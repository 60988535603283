import React, { useState , useMemo, useEffect } from "react";
import useControllerBase from "../Controller/gameControllerBase";
import useInterval from "../Helpers/UseInterval";

export default function FaceButtons(props){
    const {size} = props;
    const [pressed1, setPressed1] = useState(false);
    const [pressed2, setPressed2] = useState(false);
    const [pressed3, setPressed3] = useState(false);
    const [ori, setOri] = useState(null);
  
    const { 
        bgColor,
        buttonGlow,
        onTouchStart_Button_1,
        onTouchEnd_Button_1,
        onTouchStart_Button_2,
        onTouchEnd_Button_2,
        onTouchStart_Button_3,
        onTouchEnd_Button_3,
    } = useControllerBase();

    const b1Color = useMemo(() => {return pressed1 ? buttonGlow : 'white, rgba(180, 180, 180, 1)'}, [pressed1])
    const b2Color = useMemo(() => {return pressed2 ? buttonGlow : 'white, rgba(180, 180, 180, 1)'}, [pressed2])
    const b3Color = useMemo(() => {return pressed3 ? buttonGlow : 'white, rgba(180, 180, 180, 1)'}, [pressed3])
    const b1TextColor = useMemo(() => {return pressed1 ? 'white' : bgColor}, [pressed1])
    const b2TextColor = useMemo(() => {return pressed2 ? 'white' : bgColor}, [pressed2])
    const b3TextColor = useMemo(() => {return pressed3 ? 'white' : bgColor}, [pressed3])
    const b1TextShadow = useMemo(() => {return pressed1 ? '2px 2px 8px rgba(0,0,0, .75)' : '0 -2px 4px rgba(255,255,255, 1)'}, [pressed1])
    const b2TextShadow = useMemo(() => {return pressed2 ? '2px 2px 8px rgba(0,0,0, .75)' : '0 -2px 4px rgba(255,255,255, 1)'}, [pressed2])
    const b3TextShadow = useMemo(() => {return pressed3 ? '2px 2px 8px rgba(0,0,0, .75)' : '0 -2px 4px rgba(255,255,255, 1)'}, [pressed3])

    
    useEffect(() =>{
        function handleResize(){
            var resize = window.innerWidth;
            setOri(resize);
        }

        window.addEventListener('resize', handleResize)

        return _ => {window.removeEventListener('resize', handleResize)}
    });

    const onTouchStart = (id) => {
        switch(id){
            case '1':
                setPressed1(true);
                onTouchStart_Button_1();
                break;
            case '2':
                setPressed2(true);
                onTouchStart_Button_2();
                break;
            case '3':
                setPressed3(true);
                onTouchStart_Button_3();
                break;
        }

    }

    const onTouchEnd = (id) => {
        switch(id){
            case '1':
                setPressed1(false);
                onTouchEnd_Button_1();
                break;
            case '2':
                setPressed2(false);
                onTouchEnd_Button_2();
                break;
            case '3':
                setPressed3(false);
                onTouchEnd_Button_3();
                break;
        }

    }

    const bounding = {
        position: 'absolute', 
        top: '5%',
        right: '2.5%',
        height: '85%', maxHeight: '320px', maxWidth: '480px',
        aspectRatio: '1.25/1',
        zIndex: '10',

        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */
    }

    const ButtonsBase = {
        width: '100%',
        height: '100%',
        borderRadius: '64px',
        background: '#2A2A2D',
        outline: '4px outset white',
        boxShadow: '0 4px 32px 20px rgba(0,0,0, .25), inset 0 0 64px 64px rgba(200,200,200, .1)'
    }

    const TopButtonOutterL = {
        position: 'absolute',
        width: '45.5%',
        height: '45.5%',
        top: '3%',
        left: '3%',
        borderRadius: '56px 0 0 0',
        background: `linear-gradient( to bottom, white, rgba(180, 180, 180, 1))`,
        boxShadow: '0 4px 12px 8px rgba(0,0,0, .25), inset 0 0 12px 12px rgba(200,200,200, .1)',
        outline: '2px outset white'
    }

    const TopButtonOutterR = {
        position: 'absolute',
        width: '45.5%',
        height: '45.5%',
        top: '3%',
        right: '3%',
        borderRadius: '0 56px 0 0',
        background: `linear-gradient( to bottom, white, rgba(180, 180, 180, 1))`,
        boxShadow: '0 4px 12px 8px rgba(0,0,0, .25), inset 0 0 12px 12px rgba(200,200,200, .1)',
        outline: '2px outset white'
    }

    const TopButtonInner = {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        position: 'relative',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        top: '2px',
        left: '2px',
        borderRadius: 'inherit',
    }

    const BottomButtonOutter = {
        position: 'absolute',
        width: '94%',
        height: '45.5%',
        bottom: '3%',
        left: '3%',
        borderRadius: '0 0 56px 56px',
        background: `linear-gradient( to bottom, white, rgba(180, 180, 180, 1))`,
        boxShadow: '0 4px 12px 8px rgba(0,0,0, .25), inset 0 0 12px 12px rgba(200,200,200, .1)',
        outline: '2px outset white'
    }

    const BottomButtonInner = {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        position: 'relative',
        width: 'calc(100% - 4px)',
        height: 'calc(100% - 4px)',
        top: '2px',
        left: '2px',
        borderRadius: 'inherit',
        background: `linear-gradient( to top, ${b1Color})`,
    }

    const SpanStyle = {
        position: 'relative',
        margin: 'auto auto',
        
        pointerEvents: 'none', 
        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */

   
        fontSize: 'clamp(12pt, 5vw, 32pt)',
        fontWeight: '600',
    }


    return(

        <div id={"FaceButtons"} style={{...bounding, }}>
            <div style={{...ButtonsBase}} >
                <div style={{...TopButtonOutterL}} >
                    <div style={{...TopButtonInner, background: `linear-gradient( to top, ${b3Color})`}} onTouchStart={() => onTouchStart('3')} onTouchEnd={() => onTouchEnd('3')}>
                        <span style={{...SpanStyle, color: b3TextColor, textShadow: b3TextShadow }}>REV</span>
                    </div> 
                </div>
                <div style={{...TopButtonOutterR}} >
                    <div style={{...TopButtonInner, background: `linear-gradient( to top, ${b2Color})`}} onTouchStart={() => onTouchStart('2')} onTouchEnd={() => onTouchEnd('2')}>
                        <span style={{...SpanStyle, color: b2TextColor, textShadow: b2TextShadow }}>BOOST</span>
                    </div> 
                </div>
                <div style={{...BottomButtonOutter}} >
                    <div style={{...BottomButtonInner}} onTouchStart={() => onTouchStart('1')} onTouchEnd={() => onTouchEnd('1')}>
                        <span style={{...SpanStyle, color: b1TextColor, textShadow: b1TextShadow, fontSize: 'clamp(20pt, 8vw, 56pt)', }}>GAS</span>
                    </div> 
                </div>
            </div>
        </div>

        
        


    );
}

