import Context from "../Context/Context"
import React, { useEffect, useContext, useState, useMemo, useRef } from "react";  
import { useNavigate, useLocation } from "react-router-dom";
import {useNetworkingHooks} from "./NetworkMessaging";
import websocket from "./WebSocket";


export function CoreGameConnectionsHooks(usercontext){
    const context = useContext(Context);

    const CheckCoreGameConnectionMessages = (id, payload, senderContext) =>{
        let isMe = IsMe(senderContext);
        
        console.log(`We are checking for coremessage: `, id, payload);

        switch(id){
            case "JoinedGame":
                if(isMe){
                    
                }
                break;   
         
            case "ResponseToJoinRoomRequest":
                if(isMe){
                    console.log("TRY TO SET MY DATA!")
                    SetPlayerData(senderContext);
                }
                break;

            case "ReconnectToRoomResponse":
                if(isMe){
                    console.log("Got the reconnect response");
                    SetPlayerData(senderContext);
                    
                }
                break;

            default:
                break;


        }
    }

    const IsMe = (senderContext) => {
        if(senderContext == null) return false;
        else return senderContext.playerId === usercontext.playerId;
    }

    const SetPlayerData = (payload) => {
        context.set("playerName", payload.playerName ?? '');
             
        let color =  payload.playerColor.substring(0, payload.playerColor.length - 2);
        context.set("playerColor", color);

        let dColor =  payload.playerColorDark.substring(0, payload.playerColorDark.length - 2);
        context.set("playerColorDark", dColor);

        let bColor =  payload.playerColorBright.substring(0, payload.playerColorBright.length - 2);
        context.set("playerColorBright", bColor);
    }

    return{CheckCoreGameConnectionMessages, IsMe} ;
}