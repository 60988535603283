import websocket from "./WebSocket";

export function useNetworkingHooks(UserContext) {    
    const SendSpecificMessageToServer = (Id, Payload) =>{
    
        var msg = {
            id: Id,
            payload: JSON.stringify(Payload ?? '') ,
            userContext: UserContext,
        }

        websocket.Send(msg);
    }

    const JoinWaitingRoom = () => {
        let zone = websocket.GetSpawnZoneId();

        SendSpecificMessageToServer(`RequestToConnectToRoom_${zone}`);
    }

    const Disconnect = () => {
        SendSpecificMessageToServer("DiscontinueConnection");
    }

    const SendPressButtonToServer = (button) => {
        SendSpecificMessageToServer('ButtonDown', button);
    } 
    
    const SendReleaseButtonToServer = (button) => {
        SendSpecificMessageToServer('ButtonUp', button);
    }
    
    const SendAxisToServer = (axis) => {
        SendSpecificMessageToServer("UpdateAxisPosition", axis);
    }

    const SendPhoneOrientationToServer = (orientation) => {
        SendSpecificMessageToServer('PhoneOrientation', orientation);
    } 

    return { 
        SendSpecificMessageToServer, 
        JoinWaitingRoom, 
        Disconnect,
        SendPressButtonToServer,
        SendReleaseButtonToServer,
        SendAxisToServer ,
        SendPhoneOrientationToServer
    };
}


