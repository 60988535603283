import React, {useContext, useState, useMemo, useEffect, useRef} from "react";
import websocket from "../Helpers/WebSocket";
import { useSensors } from "../Components/Sensors";
import Joystick from "../Components/Joystick";
import Context from "../Context/Context";
import useControllerBase from "./gameControllerBase";
import { useNetworkingHooks } from "../Helpers/NetworkMessaging";
import { CoreGameConnectionsHooks } from "../Helpers/CoreGameConnections_Hooks";
import FaceButtons from "../Components/FaceButtons";

export default function DerbyController(props){
    //const Accelerometer = useAccelerometer();
    
    const [ori, setOri] = useState(null);
    const [oriAngle, setOriAngle] = useState(0);
    const [isGameOver, setGameOver] = useState(false);
    const scrollInto = useRef(null);
    const context = useContext(Context);

    //Hooks
    const controllerBase = useControllerBase();
    //const { Orientation } = useSensors({onOriChange:onOrientationChange});
    const { JoinWaitingRoom, Disconnect, SendPhoneOrientationToServer} = useNetworkingHooks(context.user);
    const {CheckCoreGameConnectionMessages} = CoreGameConnectionsHooks(context.user);

    const isLandscape = useMemo(() => { 
        if(ori != null) return ori.width > ori.height; 
        else return false;
        }, [ori]
    );

    useEffect(() => { JoinWaitingRoom(); }, []);

    useEffect(() =>{
        websocket.AddListener(onPayload);

        window.addEventListener('beforeunload', handleTabClosing);

        //Resizing
        function handleResize(){
            var resize = {
                width: window.innerWidth,
                height: window.innerHeight 
            };

            setOri(resize);
        }

        if(ori === null) handleResize();

        window.addEventListener('resize', handleResize)

        scrollInto.current.scrollIntoView();

        //Iphone Prevent Default
        document.addEventListener("touchmove", preventBehavior, {passive: false});

        return _ => {
            window.removeEventListener('resize', handleResize);
            websocket.RemoveListener(onPayload);
            window.removeEventListener('beforeunload', handleTabClosing);
            document.removeEventListener("touchmove", preventBehavior, {passive: false});
        }
    }, []);

    const handleTabClosing = () => {
        Disconnect();
    }

    function preventBehavior(e) {
        e.preventDefault(); 
    };

    function onPayload(id, payload, senderContext){      
        CheckCoreGameConnectionMessages(id, payload, senderContext);

        //Game specific calls
        switch(id){
           
            case "TeamSetup":
                SetupController(payload);
                break;
            case "GameOver":
                setGameOver(true);
                break;
            default:
                break;
        }
    }

    function SetupController(payload)
    {        
        //Setup Stuff Here
    }

    function onOrientationChange(orientation){
    
        let o = Math.round(orientation.z * 100) / 100;
        
        if(Math.abs(o) < 10) o = 0;


        setOriAngle(o);
        SendPhoneOrientationToServer(o)
    }

    const pColor = useMemo(() => {return context.user.playerColor ?? 'black'}, [context]);

    const PlayerColorBanner = {
        position: 'fixed',
        width: '100%',
        height: '24px',
        left: '0',
        background: `linear-gradient(to right, ${pColor} 25%, rgba(0,0,0,0) 75% )`,
        zIndex: '0'
    }

    const PlayerNameBanner = {
        position: 'fixed',
        width: '100%',
        height: '76px',
        left: '2.5%',
        zIndex: '0'
    }

    const SpanStyle = {
        position: 'relative',
        left: '0',
        margin: '0 auto',
        pointerEvents: 'none', 
        
        userSelect: 'none',
        WebkitUserSelect: 'none', 
        MozUserSelect: 'none', /* Firefox */
        MsUserSelect: 'none', /* Internet Explorer/Edge */

        color: 'white', 
        textShadow: '2px 2px 8px rgba(0,0,0, .75)',

        fontSize: 'clamp(20pt, 8vw, 56pt)',
        fontWeight: '600',
    }

    return(
        <div ref={scrollInto} style={{backgroundColor: controllerBase.bgColor, width: '100%', height: '100%', overflow: 'hidden', touchAction: 'none', zIndex: '-1'}}>
        
            {isGameOver ? 
            <GameOverWarning glow={controllerBase.bgColor} /> : 
            (isLandscape ? 
            <>
                <div style={{...PlayerColorBanner, top: '0'}} />
                <div style={{...PlayerNameBanner, top: '24px'}}>
                    <span style={{...SpanStyle}}>{context.user.playerName}</span>
                </div>
                <Joystick size={300} padSize={120} orientation={ori} />
                <FaceButtons size={300} />
                <div style={{...PlayerColorBanner, bottom: '0'}} />
            </> : 
            <RotationWarning glow={controllerBase.bgColor}/>)
            }
        
            
        </div>
    );
}



function RotationWarning(props){
    const {glow, orientation, c} = props;

    const WarningBox = {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        boxShadow: 'inset 0 0 256px 12px rgba(0,0,0, .5)'
    }

    const SpanStyle = {
        position: 'relative',
        width: '80%',
        margin: 'auto auto',
        pointerEvents: 'none', 
        userSelect: 'none',
   
        fontSize: 'clamp(40pt, 10vw, 56pt)',
        fontWeight: '600',
        textAlign: 'center',
        color: 'white',
        textShadow: '2px 2px 8px rgba(0,0,0, .75)'
    }

    return(
    <div style={{...WarningBox}}>
        <span style={{...SpanStyle}}>Please Rotate Your Device</span>
    </div>

    );

}

function GameOverWarning(props){
    const {glow, orientation, c} = props;

    const WarningBox = {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        height: '100%',
        boxShadow: 'inset 0 0 256px 12px rgba(0,0,0, .5)'
    }

    const SpanStyle1 = {
        position: 'relative',
        width: '90%',
        margin: 'auto auto',
        pointerEvents: 'none', 
        userSelect: 'none',
   
        fontSize: 'clamp(40pt, 10vw, 56pt)',
        fontWeight: '600',
        textAlign: 'center',
        color: 'white',
        textShadow: '2px 2px 8px rgba(0,0,0, .75)'
    }

    const SpanStyle2 = {
        position: 'relative',
        width: '75%',
        margin: 'auto auto',
        pointerEvents: 'none', 
        userSelect: 'none',
   
        fontSize: 'clamp(20pt, 5vw, 40pt)',
        fontWeight: '600',
        textAlign: 'center',
        color: 'white',
        textShadow: '2px 2px 8px rgba(0,0,0, .75)'
    }

    return(
    <div style={{...WarningBox}}>
        <span style={{...SpanStyle1}}>Thank you for playing!</span>
        <span style={{...SpanStyle2}}>Please close this page and rescan to play again.</span>
    </div>

    );

}