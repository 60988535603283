import React from "react";
import './App.css';
import { useCallback } from 'react';
import { BrowserRouter, Route, Routes,  Link } from "react-router-dom";
import DerbyController from './Controller/DerbyController';
import GameContextProvider from './Context/GameContextProvider';

export default function App() {

  const FixedScreen = {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    position: 'fixed',

    userSelect: 'none',
    WebkitUserSelect: 'none', 
    MozUserSelect: 'none', /* Firefox */
    MsUserSelect: 'none', /* Internet Explorer/Edge */
  }

  const onTouch = useCallback(
    (e) => {
      e.preventDefault();
    },
    [],
  );

  const divRefCallback = useCallback(
    (node) => {
      console.log(node);
      if (node == null) {
        return;
      }
      node.addEventListener('touchstart', onTouch, { passive: false });
      node.addEventListener('touchmove', onTouch, { passive: false });
    },
    [onTouch],
  );

  return (
    <div ref={divRefCallback} style={{...FixedScreen}}>
      <GameContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/Derby" element={<DerbyController />} exact/>
          </Routes>
        </BrowserRouter>
      </GameContextProvider>
    </div>
  );
}

