import React, { Component } from "react";
import Context from './Context';
import { GenerateUniqueID } from "../Helpers/GenerateUniquePlayeID";

class GameContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      context: {
        gameId: '',
        playerId: '',
        playerControllerId: '',
        playerColor: '',
        playerColorDark: '',
        playerColorBright: '',
        playerName: ' TheBehemoth',
        playerScore: 64,  
      }
    }
  }

  componentDidMount() {   
    this.LoadContextInformation();
  }

  LoadContextInformation = () =>{
      let ID = this.playerId;

      if(localStorage.getItem('PlayerId')){
        this.set('playerId', localStorage.getItem('PlayerId'));
      }
      else{
        if(ID === undefined || ID === null || ID === ''){
          ID = GenerateUniqueID();
          this.set('playerId', ID);
          localStorage.setItem('PlayerId', ID);
        }
      }

      let name = this.playerName;

      if(name == null){
        name = localStorage.getItem('PlayerName') ?? 'Unknown Player';
        this.set('playerName', name);
      }

      let cID = this.playerControllerId;

      if(cID == null){
        cID = localStorage.getItem('PlayerControllerID') ??`P${((Math.floor(Math.random() * 100) % 4)+1)}` ;
        this.set('playerControllerId', cID);
      }
}

  changeValue = (value) => {
    this.setState({
      value
    })
  }

  set = (key, value) => {
    var context = this.state.context;
    context[key] = value;
    this.setState({
      context
    })
  }
  render() {
    const context = {
      user: this.state.context,
      set: this.set
    };

    return (
      <Context.Provider value={context}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default GameContextProvider;
